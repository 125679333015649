import {
  Icon,
  Link,
  Stack,
  Text,
  VStack,
  StackProps,
} from '@chakra-ui/react'
import React from 'react'
import { DiGithubBadge } from 'react-icons/di'
import { FaYoutube } from 'react-icons/fa'
import { EnvelopeIcon  } from "@heroicons/react/24/solid";
import { t } from 'utils/en/i18n'

type FooterLinkProps = {
  icon?: React.ElementType
  href?: string
  label?: string
}

const FooterLink = ({ icon, href, label }: FooterLinkProps) => (
  <Link display='inline-block' href={href} aria-label={label} isExternal>
    <Icon as={icon} fontSize='xl' color='gray.400' />
  </Link>
)

const links = [
  {
    icon: DiGithubBadge,
    label: 'GitHub',
    href: 'https://github.com/myformao',
  },
  {
    icon: FaYoutube,
    label: 'YouTube',
    href: 'https://www.youtube.com/@myformao',
  },
  {
    icon: EnvelopeIcon,
    label: 'Email',
    href: 'mailto:',
  },
]

export const Footer = (props: StackProps) => (
  <VStack as='footer' spacing={4} mt={12} textAlign='center' {...props}>
    <Text fontSize='sm'>
      <span>
        {t('component.footer.proudly-made-in')}
      </span>
    </Text>
    <Stack mt={4} direction='row' spacing='12px' justify='center'>
      {links.map((link) => (
        <FooterLink key={link.href} {...link} />
      ))}
    </Stack>
  </VStack>
)

export default Footer

import {
  Box,
  Button,
  Divider,
  Link,
  Text,
  Wrap,
  WrapItem,
  chakra,
} from '@chakra-ui/react'

import * as React from 'react'

import users from 'chakra-users'
import ChakraNextImage from 'components/chakra-next-image'
import Container from 'components/container'
import { Footer } from 'components/footer'
import Header from 'components/header'

import SEO from 'components/seo'

import { getAllContributors } from 'utils/get-all-contributors'
import { getAllMembers } from 'utils/get-all-members'
import { getAllSponsors } from 'utils/get-all-sponsors'
import { getDiscordMembers } from 'utils/get-discord-members'
import { getGithubStars } from 'utils/get-github-stars'
import { getNpmDownloads } from 'utils/get-npm-downloads'
import { t } from 'utils/en/i18n'

const openCollectiveLink = 'https://opencollective.com/chakra-ui'

const HomePage = () => {
  return (
    <>
      <SEO
        title={t('homepage.seo.title')}
        description={t('homepage.seo.description')}
      />

      <Header />
      <Box mb={20}>
        <Box as='section' pt='6rem' pb={{ base: '0', md: '5rem' }}>
          <Container>
            <Box textAlign='center'>
              <chakra.h1
                maxW='16ch'
                mx='auto'
                fontSize={{ base: '2.25rem', sm: '3rem', lg: '4rem' }}
                fontFamily='heading'
                letterSpacing='tighter'
                fontWeight='extrabold'
                mb='16px'
                lineHeight='1.2'
              >
                {t('homepage.title.main')}
                <Box as='span' color='teal.500' _dark={{ color: 'teal.300' }}>
                  {' '}
                  {t('homepage.title.highlighted')}
                </Box>
              </chakra.h1>

              <Text
                maxW='560px'
                mx='auto'
                color='gray.500'
                _dark={{ color: 'gray.400' }}
                fontSize={{ base: 'lg', lg: 'xl' }}
                mt='6'
              >
                {t('homepage.message')}
              </Text>
            </Box>


        <Box as='section' pt='48px' pb='32px'>
          <Container textAlign='center'>
            <chakra.p
              fontWeight='500'
              textStyle='caps'
              color='teal.600'
              _dark={{ color: 'teal.300' }}
              mb='48px'
            >
              {t('homepage.coming-soon')}
            </chakra.p>
        
          </Container>
        </Box>
      </Container>
        </Box>
        <Divider />

        <Footer />
      </Box>

    </>
  )
}

export default HomePage
